import { SwatchOrderingModalDefault } from '~/swatch-ordering/Components/SwatchOrderingModalDefault';

const defaultSwatchOrderingKey = 'DEFAULT';

const swatchOrderingModalComponents = {
	DEFAULT: SwatchOrderingModalDefault,
};

const swatchOrderingModalTimeout = 1000;

export {
	defaultSwatchOrderingKey,
	swatchOrderingModalComponents,
	swatchOrderingModalTimeout,
};
