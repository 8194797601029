import { isMobile, apiUrl } from '~/global/global.constants';

const defaultEmailSignUpKey = 'DEFAULT';

const emailSignUpEndpointUrl = `${apiUrl}/api/email-preferences/create-subscription`;

const emailSignUpModalContents = {
	DEFAULT: {
		headingText: '',
		imageName: '',
		imageWidth: 0,
		modalWidth: 660,
		subheadingText: '',
	},
	FIRST_TO_KNOW: {
		headingText: 'Be the first to know!',
		imageName: 'novato_email_signup_modal',
		imageWidth: 270,
		modalWidth: 660,
		subheadingText: 'Subscribers are the first to hear about new products and clearance events.',
	},
	FIRST_TO_KNOW_NO_IMAGE: {
		headingText: 'Be the first to know!',
		imageWidth: 0,
		modalWidth: 445,
		subheadingText: 'Subscribers are the first to hear about new products and clearance events.',
	},
	MOST_BEAUTIFUL_EMAILS: {
		headingText: 'The most beautiful emails in your inbox.',
		imageName: 'callan_email_signup_modal',
		imageWidth: 270,
		modalWidth: 660,
		subheadingText: 'Modern design inspiration and ideas, right at your fingertips.',
	},
	SLIM: {
		headingText: 'So much more to see!',
		imageWidth: 0,
		modalWidth: 445,
		subheadingText: 'Sign up for emails to be the first to know about new products and clearance events.',
	},
};

const emailSignUpLocations = {
	DEFAULT: 'DEFAULT',
	PRIMARY: 'PRIMARY',
	SECONDARY: 'SECONDARY',
};

const emailSignUpModalTimeout = 1000;

const emailSignUpSources = {
	DEFAULT: 'FOOTER',
	FOOTER: 'FOOTER',
	MOBILE: 'MOBILE',
	MONETATE_MODAL: isMobile ? 'PROACTIVE_MOBILE' : 'PROACTIVE_DESKTOP',
};

export {
	defaultEmailSignUpKey,
	emailSignUpEndpointUrl,
	emailSignUpLocations,
	emailSignUpModalContents,
	emailSignUpSources,
	emailSignUpModalTimeout,
};
