'use client';

import React from 'react';
import { observer } from 'mobx-react';

const OutdoorGiveawayModal = observer(class OutdoorGiveawayModal extends React.Component {
	render() {
		const {
			outdoorGiveawayModalStore: {
				outdoorGiveawayModalSuccessCallback,
				outdoorGiveawayModalComponent: OutdoorGiveawayModalComponent,
			}
		} = this.props;

		return (
			<OutdoorGiveawayModalComponent clickHandler={outdoorGiveawayModalSuccessCallback} />
		);
	}
});

export { OutdoorGiveawayModal };
