import { FunctionComponent } from 'react';

import { headerLevelType } from '~/components/magic-header';
import {
	MonetateAction,
	MonetateActionType,
	MonetateActionHero,
	MonetateActionSubfeature,
	REGION,
} from '~/monetate/Types/Monetate.type';
import { BANNER_ID } from '~/features/banners/Types/BannerLibrary.type';

/* eslint-disable camelcase */
export enum CONTEXT {
	HOME_PAGE = 'HOME_PAGE',
}

export enum SETTING {
	DEFAULT = 'DEFAULT',
}

export enum HERO_ID {
	HH_BCORP_C0923 = 'HH_BCORP_C0923',
	HH_BdrRtr_S0121 = 'HH_BdrRtr_S0121',
	HH_Bth_S0321 = 'HH_Bth_S0321',
	HH_Din_S0121 = 'HH_Din_S0121',
	HH_Liv_S0720 = 'HH_Liv_S0720',
	HH_Office_S0121 = 'HH_Office_S0121',
	HH_OUTDOOR_S1123 = 'HH_OUTDOOR_S1123',
	HH_Out_S0121 = 'HH_Out_S0121',
	HH_Storage_S0121 = 'HH_Storage_S0121',
	HH_Clearance_1122 = 'HH_Clearance_1122',
	HH_Newmarkdowns_1222 = 'HH_Newmarkdowns_1222',
	HH_MIDYEARCLEARANCE_0323 = 'HH_MIDYEARCLEARANCE_0323',
}

export enum SUBFEATURE_ID {
	SF_4KGIVEAWAY_B1023 = 'SF_4KGIVEAWAY_B1023',
	SF_Affr0STR_B0420 = 'SF_Affr0STR_B0420',
	SF_BATHDIGI_E0523 = 'SF_BATHDIGI_E0523',
	SF_BCORP_A0923 = 'SF_BCORP_A0923',
	SF_BEDROOMDIGI_E0523 = 'SF_BEDROOMDIGI_E0523',
	SF_Clearance_1122 = 'SF_Clearance_1122',
	SF_DelRate_D0820 = 'SF_DelRate_D0820',
	SF_ELYEVENT_B0723 = 'SF_ELYEVENT_B0723',
	SF_EMMET_B0623 = 'SF_EMMET_B0623',
	SF_FallDecor_E0722 = 'SF_FallDecor_E0722',
	SF_Falldigi_E0922 = 'SF_Falldigi_E0922',
	SF_FallDiningDigi_F0922 = 'SF_FallDiningDigi_F0922',
	SF_FreshFinds_E0623 = 'SF_FreshFinds_E0623',
	SF_FDS4_A0720 = 'SF_FDS4_A0720',
	SF_GIFTCARDS_D1123 = 'SF_GIFTCARDS_D1123',
	SF_GIFTSGCARDS_E082023 = 'SF_GIFTSGCARDS_E082023',
	SF_GRIFFINRECALL_D1123 = 'SF_GRIFFINRECALL_D1123',
	SF_Media_F0121 = 'SF_Media_F0121',
	SF_METRO_A0922 = 'SF_METRO_A0922',
	SF_MIDYEARCLEARANCE_0323 = 'SF_MIDYEARCLEARANCE_0323',
	SF_NewLiv_A0122 = 'SF_NewLiv_A0122',
	SF_NewOut_1122 = 'SF_NewOut_1122',
	SF_Newmarkdowns_1222 = 'SF_Newmarkdowns_1222',
	SF_OakbrookOutlet_B1022 = 'SF_OakbrookOutlet_B1022',
	SF_OPENHOUSE_D0123 = 'SF_OPENHOUSE_D0123',
	SF_OUTDOORBESTSELLERS_F1223 = 'SF_OUTDOORBESTSELLERS_F1223',
	SF_OUTDOORDIGI_E0223 = 'SF_OUTDOORDIGI_E0223',
	SF_OUTDOORGIVEAWAY_B0323 = 'SF_OUTDOORGIVEAWAY_B0323',
	SF_PARAMUS_1023 = 'SF_PARAMUS_1023',
	SF_PARSONSDINING_E0922 = 'SF_PARSONSDINING_E0922',
	SF_SEASONALFAVS_E0823 = 'SF_SEASONALFAVS_E0823',
	SF_SLIMC_E0922 = 'SF_SLIMC_E0922',
	SF_SPRINGDIGI_B0423 = 'SF_SPRINGDIGI_B0423',
	SF_SustainFavs_F0322 = 'SF_SustainFavs_F0322',
	SF_SUSTAINABILITY_A0123 = 'SF_SUSTAINABILITY_A0123',
	SF_WINTERDIGI_E1222 = 'SF_WINTERDIGI_E1222',
	SF_TIDYUP_B1023 = 'SF_TIDYUP_B1023',
	//CATEGORY BANNERS
	CAT_AFFIRM_EVENT = 'CAT_AFFIRM_EVENT',
	CAT_CLEARANCE_EVENT = 'CAT_CLEARANCE_EVENT',
	CAT_CLEARANCE_AFFIRM_NONEVENT = 'CAT_CLEARANCE_AFFIRM_NONEVENT',
}

export enum PAGE_TYPE {
	MAIN = 'MAIN',
	MISSING = 'MISSING',
}

export interface FeatureDefaults {
	[REGION.HERO]?: HERO_ID,
	[REGION.SUBFEATURE]?: SUBFEATURE_ID[],
}

export interface Feature extends MonetateAction {
	headerLevel?: headerLevelType,
	sliderHeaderLevel?: headerLevelType,
	trLinkEventCompType?: string,
	children: any,
	component: FunctionComponent<any>,
}

export type RegionLinkEventCompTypes = {
	[region in REGION]: {
		trLinkEventCompType: string,
	}
}

export type FeatureDefaultSettings = {
	[setting in SETTING]: {
		[REGION.HERO]?: HERO_ID,
		[REGION.SUBFEATURE]?: SUBFEATURE_ID[],
	};
};

export interface LibraryProps {
	component: HERO_ID | SUBFEATURE_ID | BANNER_ID | undefined,
	region: REGION,
	monetate: MonetateActionType,
	headerLevel: headerLevelType,
	sliderHeaderLevel: headerLevelType,
	priority?: boolean,
}

export const isValidHero = (
	component: MonetateActionType,
): component is MonetateActionHero => {
	return component.json.component in HERO_ID && component.json.region === REGION.HERO;
};

export const isValidSubfeature = (
	component: MonetateActionType,
): component is MonetateActionSubfeature => {
	return component.json.component in SUBFEATURE_ID && component.json.region === REGION.SUBFEATURE;
};

export const isValidSubfeatureKey = (
	key: string | undefined,
): key is SUBFEATURE_ID => {
	return key !== undefined && key in SUBFEATURE_ID;
};
