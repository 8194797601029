import Cookie from 'js-cookie';
import React from 'react';

import { TextLink } from '~/components/Buttons/Components/TextLink';
import { S7Image } from '~/components/Images/S7Image';
import { s7ImagePath } from '~/global/global.constants';

export const StorageGiveawayModalDefault = ({ clickHandler }) => {
	function handleClick() {
		Cookie.set('rnb.STORAGE_GIVEAWAY_ENTERED', true, { expires: 30 });

		if (clickHandler) {
			clickHandler();
		}
	}

	return (
		<div className="tw-absolute tw-bg-white tw-left-0 tw-top-0">
			<div className="md:tw-hidden">
				<S7Image
					alt=""
					height="400"
					src={`${s7ImagePath}/storageGiveaway4K_sm`}
					width="660"
				/>
			</div>
			<div className="tw-hidden md:tw-block">
				<S7Image
					alt=""
					height="400"
					src={`${s7ImagePath}/storageGiveaway4K`}
					width="660"
				/>
			</div>
			<div className="tw-p-5">
				<h2 className="tw-heading-3 tw-mb-3">$4K Storage Giveaway</h2>
				<p>
					Organize your home for the new year. We’re giving away a $4,000 gift card to help one lucky winner get started with modern storage solutions. Enter for a chance to win now through January 7.
				</p>
				<a className="HollowButton HollowButton--gray tw-font-semibold tw-mb-5" href="https://go.roomandboard.com/D36F83" onClick={handleClick} target="_blank" rel="noreferrer">Enter Now</a>
				<p className="tw-m-0 tw-text-2xs tw-text-gray-300">*Void where prohibited. No purchase necessary to enter; a purchase will not increase your chance of winning. Subject to <TextLink href="https://go.roomandboard.com/8EAE39" target="_blank"><span className="tw-text-2xs">Official Rules</span></TextLink>.</p>
			</div>
		</div>
	);
};
