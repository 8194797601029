'use client';

import React from 'react';
import { observer } from 'mobx-react';

const FDSModal = observer(class FDSModal extends React.Component {
	render() {
		const {
			fdsModalStore: {
				isMediaQueryMd,
				fdsModalSuccessCallback,
				fdsModalComponent: FDSModalComponent,
			}
		} = this.props;

		return (
			<FDSModalComponent isMediaQueryMd={isMediaQueryMd} clickHandler={fdsModalSuccessCallback} />
		);
	}
});

export { FDSModal };
