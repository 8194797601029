import { action, makeObservable } from 'mobx';

class FeatureTogglesModel {
	globalDynamicModel;

	constructor(globalDynamicModel) {
		makeObservable(this, {
			getToggles: action.bound,
			getToggleByName: action.bound,
			isOn: action.bound,
			getToggleClassNames: action.bound,
		});

		this.globalDynamicModel = globalDynamicModel;
	}

	getToggles() {
		return this.globalDynamicModel?.activeFeatureToggles || [];
	}

	getToggleByName(name) {
		return this.getToggles().find(toggle => toggle === name) || {};
	}

	isOn(featureName) {
		const toggles = this.getToggles();

		if (!Array.isArray(toggles)) {
			return false;
		}
		return toggles.indexOf(featureName) > -1;
	}

	// @deprecated
	getToggleClassNames() {
		return '';
	}
}

export { FeatureTogglesModel };
