import Cookie from 'js-cookie';
import {
	action, computed, observable, makeObservable
} from 'mobx';
import React from 'react';

import {
	defaultOutdoorGiveawayKey,
	outdoorGiveawayModalComponents,
	outdoorGiveawayModalTimeout,
} from '~/outdoor-giveaway-modal/outdoorGiveawayModal.constants';
import { OutdoorGiveawayModal } from '~/outdoor-giveaway-modal/Components/OutdoorGiveawayModal';
import { OutdoorGiveawayModalWrapper } from '~/components/magic-modal/Components/OutdoorGiveawayModal';

class OutdoorGiveawayModalStore {
	outdoorGiveawayModalId = '';

	outdoorGiveawayModalSuccess = false;

	constructor() {
		makeObservable(this, {
			outdoorGiveawayModalId: observable,
			outdoorGiveawayModalSuccess: observable,
			outdoorGiveawayModalComponent: computed,
			openOutdoorGiveawayModal: action.bound,
			outdoorGiveawayModalSuccessCallback: action.bound
		});
	}

	get outdoorGiveawayModalComponent() {
		return outdoorGiveawayModalComponents[this.outdoorGiveawayModalId] || outdoorGiveawayModalComponents[defaultOutdoorGiveawayKey];
	}

	deferOrOpenOutdoorGiveawayModal() {
		const monetateModalCookie = Cookie.get('rnb.MONETATE_MODAL');

		if (!monetateModalCookie) {
			if (this.magicModal.model.isOpen) {
				this.magicModal.model.onCloseModal = () => {
					setTimeout(this.openOutdoorGiveawayModal, outdoorGiveawayModalTimeout);
				};
			} else {
				this.openOutdoorGiveawayModal();
			}
		}
	}

	openOutdoorGiveawayModal() {
		this.magicModal.openModal({
			reverseCloseButton: false,
			WrapperComponent: OutdoorGiveawayModalWrapper,
			flushSides: true,
			containerClass: 'monetate-modal',
			content: {
				children: (
					<OutdoorGiveawayModal outdoorGiveawayModalStore={this} />
				),
			},
			title: false,
			maxWidth: 660,
		});

		Cookie.set('rnb.MONETATE_MODAL', 'OUTDOOR_GIVEAWAY');
	}

	outdoorGiveawayModalSuccessCallback() {
		this.outdoorGiveawayModalSuccess = true;

		this.magicModal.closeModal();
	}
}

export const OutdoorGiveawayModalStoreFactory = {
	create: ({
		outdoorGiveawayModalId = defaultOutdoorGiveawayKey,
	} = {}, magicModal) => {
		const outdoorGiveawayModalStore = new OutdoorGiveawayModalStore();

		Object.assign(outdoorGiveawayModalStore, {
			outdoorGiveawayModalId,
			magicModal,
		});

		// global.outdoorGiveawayModalStore = outdoorGiveawayModalStore;

		return outdoorGiveawayModalStore;
	},
};
