import React from 'react';

import { LazyPicture } from '~/util/Components/LazyPicture';
import { s7ImagePath } from '~/global/global.constants';

export const SwatchOrderingModalDefault = (props) => {
	const { clickHandler } = props;

	return (
		<div>
			<div className="tw-flex tw-justify-center tw-items-center md:tw-gap-5 tw-gap-2">
				<div className="tw-w-1/2">
					<LazyPicture
						mediumSrcSet={`${s7ImagePath}/swatchModalMonetate_0623?size=600,760&scl=1 2x`}
						smallSrcSet={`${s7ImagePath}/swatchModalMonetate_sm_0623?size=350,650&scl=1 2x`}
						fallbackSrc={`${s7ImagePath}/swatchModalMonetate_0623?size=300,380&scl=1`}
					/>
				</div>
				<div className="tw-w-1/2 tw-pr-4">
					<h2 className="md:tw-heading-3 tw-heading-4 tw-mb-2">Free Swatches</h2>
					<p className="tw-body-2">Get up to 20 free swatches to see how they look and feel in your space.</p>
					<a className="HollowButton HollowButton--gray" href="/ideas-advice/explore-materials/fabrics/indoor" onClick={clickHandler}>Order Swatches</a>
				</div>
			</div>
		</div>
	);
};
