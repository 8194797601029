import {
	action, reaction, observable, extendObservable, makeObservable,
} from 'mobx';

import { sitePath, isOnServer, monetateEnabled } from '~/global/global.constants';
import { apiEventKeys } from '~/global/monetate/monetate.constants';
import { trackApiEvent } from '~/global/monetate/monetate.utils';

class MonetateStore {
	EmailSignUpModal;

	FDSModal;

	OutdoorGiveawayModal;

	SwatchOrderingModal;

	disposers;

	emailSignUpModalStore;

	monetateData;

	rawMonetateData;

	constructor() {
		makeObservable(this, {
			EmailSignUpModal: observable.ref,
			FDSModal: observable.ref,
			OutdoorGiveawayModal: observable.ref,
			SwatchOrderingModal: observable.ref,
			addIframeSupport: action.bound,
			makeSourceAppDataObservable: action.bound,
		});
	}

	addIframeSupport() {
		if (isOnServer) {
			return;
		}
		// allow iframes to set monetateData
		// deepcode ignore InsufficientPostmessageValidation: we are validating the message event origin against sitePath
		global.addEventListener('message', ({ data = '{}', origin }) => {
			try {
				const { monetateData: postMessageMonetateData } = JSON.parse(data);

				if ((origin.indexOf(sitePath) > -1) && postMessageMonetateData) {
					Object.assign(this, postMessageMonetateData);
				}
			} catch (error) {} // eslint-disable-line no-empty
		});
	}

	makeSourceAppDataObservable() {
		return observable(Object.entries(this.rawMonetateData).reduce((accumulatedGlobals, [monetateKey, monetateValue]) => {
			const existingMonetateDataObj = global.RNB?.Monetate?.appData || {};
			const existingMonetateData = existingMonetateDataObj[monetateKey];

			// convert the source appData to be observable
			if (existingMonetateDataObj) {
				extendObservable(global.RNB?.Monetate?.appData, {
					[monetateKey]: existingMonetateData,
				});
			}
			// created a computed for the exported observable object, which will propagate the observable source appData
			return {
				...accumulatedGlobals,
				get [monetateKey]() {
					return global.RNB?.Monetate?.appData?.[monetateKey] || monetateValue;
				},
			};
		}, {}));
	}

	init() {
		// set(global, 'RNB.Monetate.appData', {});
		global.RNB = global.RNB || {};
		global.RNB.Monetate = global.RNB.Monetate || {};
		global.RNB.Monetate.appData = {};
		global.RNB.Monetate.appData.Features = [];

		this.disposers = [];
		this.monetateData = this.makeSourceAppDataObservable();
		this.addIframeSupport();
	}
}

export const MonetateStoreFactory = {
	create(rawMonetateData, {
		emailSignUpModalStore,
		fdsModalStore,
		outdoorGiveawayModalStore,
		storageGiveawayModalStore,
		swatchOrderingModalStore,
	}) {
		if (!monetateEnabled) {
			return {};
		}
		const store = new MonetateStore();
		const {
			EmailSignUpModal = '',
			FDSModal = '',
			OutdoorGiveawayModal = '',
			StorageGiveawayModal = '',
			SwatchOrderingModal = '',
		} = rawMonetateData;

		store.rawMonetateData = rawMonetateData;
		store.init();

		Object.assign(store, {
			EmailSignUpModal,
			emailSignUpModalStore,
			FDSModal,
			fdsModalStore,
			OutdoorGiveawayModal,
			outdoorGiveawayModalStore,
			StorageGiveawayModal,
			storageGiveawayModalStore,
			SwatchOrderingModal,
			swatchOrderingModalStore,
		});

		if (!isOnServer) {
			store.disposers.push(
				reaction(() => {
					return store.EmailSignUpModal;
				}, (emailSignUpModalId) => {
					Object.assign(store.emailSignUpModalStore, { emailSignUpModalId });
					store.emailSignUpModalStore.deferOrOpenEmailSignUpModal();
				}),
				reaction(() => {
					return store?.emailSignUpModalStore?.emailSignUpModalSuccess;
				}, (emailSignUpModalSuccess) => {
					if (emailSignUpModalSuccess) {
						trackApiEvent([apiEventKeys.EMAIL_SIGNUP_MODAL]);
					}
				}),
				reaction(() => {
					return store.SwatchOrderingModal;
				}, (swatchOrderingModalId) => {
					Object.assign(store.swatchOrderingModalStore, { swatchOrderingModalId });

					store.swatchOrderingModalStore.deferOrOpenSwatchOrderingModal();
				}),
				reaction(() => {
					return store?.swatchOrderingModalStore?.swatchOrderingModalSuccess;
				}, (swatchOrderingModalSuccess) => {
					if (swatchOrderingModalSuccess) {
						trackApiEvent([apiEventKeys.SWATCH_ORDERING_MODAL]);
					}
				}),
				reaction(() => {
					return store.FDSModal;
				}, (fdsModalId) => {
					Object.assign(store.fdsModalStore, { fdsModalId });

					store.fdsModalStore.deferOrOpenFDSModal();
				}),
				reaction(() => {
					return store?.fdsModalStore?.fdsModalSuccess;
				}, (fdsModalSuccess) => {
					if (fdsModalSuccess) {
						trackApiEvent([apiEventKeys.FDS_MODAL]);
					}
				}),
				reaction(() => {
					return store.OutdoorGiveawayModal;
				}, (outdoorGiveawayModalId) => {
					Object.assign(store.outdoorGiveawayModalStore, { outdoorGiveawayModalId });

					store.outdoorGiveawayModalStore.deferOrOpenOutdoorGiveawayModal();
				}),
				reaction(() => {
					return store?.outdoorGiveawayModalStore?.outdoorGiveawayModalSuccess;
				}, (outdoorGiveawayModalSuccess) => {
					if (outdoorGiveawayModalSuccess) {
						trackApiEvent([apiEventKeys.OUTDOOR_GIVEAWAY]);
					}
				}),
			);

			window.mtStore = store;
		}

		return store;
	},
};
