import {
	action, observable, reaction, makeObservable,
} from 'mobx';

import type { TopMenu } from '~/global/top-menu/Models/Menu.model';

export class TopMenuMobileStore {
	isOpen = false;

	menus: Array<TopMenu> = [];

	menuWasActivated = false;

	menuWasDeactivated = false;

	wasClosed = false;

	wasOpened = false;

	constructor(menus: Array<TopMenu>) {
		makeObservable(this, {
			isOpen: observable,
			menuWasActivated: observable,
			menuWasDeactivated: observable,
			wasClosed: observable,
			wasOpened: observable,
			activateMenu: action,
			close: action,
			deactivateMenus: action,
			giveFocus: action,
			open: action,
		});

		this.isOpen = false;
		this.menus = menus;
		this.menuWasActivated = false;
		this.menuWasDeactivated = false;
		this.wasClosed = false;
		this.wasOpened = false;
	}

	giveFocus(el: HTMLLinkElement | HTMLElement | null) {
		if (el) {
			el.focus();
		}
	}

	activateMenu(menu: TopMenu) {
		this.menus.forEach((theMenu) => {
			theMenu.isActive = false;
		});

		menu.isActive = true;

		this.menuWasActivated = true;

		this.menuWasDeactivated = false;

		// give focus to the menu's back button
		setTimeout(() => {
			const backButton: HTMLLinkElement | null = document.querySelector(`[data-mobile-submenu-back="${(menu.title).toUpperCase()}"]`);
			this.giveFocus(backButton);
		}, 5);
	}

	close() {
		// wait for the slide out animation to complete
		setTimeout(() => {
			this.isOpen = false;
			// give focus to the open menu button
		}, 200);
		this.wasClosed = true;

		this.wasOpened = false;
	}

	deactivateMenus({ nextFocusSelector = null }: {nextFocusSelector: string | null}) {
		// wait for the slide out animation to complete
		setTimeout(() => {
			this.menus.forEach((menu) => {
				menu.isActive = false;
			});
		}, 200);

		setTimeout(() => {
			if (nextFocusSelector) {
				const focusEl: HTMLElement | null = document.querySelector(nextFocusSelector);
				this.giveFocus(focusEl);
			}
		}, 300);

		this.menuWasActivated = false;

		this.menuWasDeactivated = true;
	}

	get primaryMenus() {
		return this.menus.filter((menu: TopMenu) => !menu.isSecondaryMenu);
	}

	get secondaryMenus() {
		return this.menus.filter((menu: TopMenu) => menu.isSecondaryMenu);
	}

	get ideasAdviceMenu() {
		// return null;
		return this.menus.find(({ dataKey }) => dataKey === 'ideas-and-advice');
	}

	open() {
		this.isOpen = true;

		this.wasClosed = false;

		this.wasOpened = true;
	}
}

export const TopMenuMobileStoreFactory = {
	create: (menus: Array<TopMenu>) => {
		const topMenuMobileStore = new TopMenuMobileStore(menus);

		reaction(() => {
			return topMenuMobileStore.wasClosed;
		}, (wasClosed) => {
			if (wasClosed) {
				// after closing we the main menu want to return focus to the open button
				topMenuMobileStore.deactivateMenus({ nextFocusSelector: '[data-button="mobileOpenBtn"]' });
			}
		});

		return topMenuMobileStore;
	},
};
