'use client';

import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { useGlobalContext } from '~/global/Contexts/Global.context';
import { EmailSignUpFormViewState } from '~/email-signup/Components/EmailSignUpForm';
import { EmailSignUpModalImage } from '~/email-signup/Components/EmailSignUpModalImage';

import styles from '#/layout/email-signup.module.scss';

export const EmailSignUpModal = observer(() => {
	const {
		emailSignUpModalStore: {
			emailSignUpFormStore,
			emailSignUpModalContent: {
				headingText,
				imageName,
				imageWidth,
				subheadingText,
			},
			isNoImageEmailSignUpModalContent,
		}
	} = useGlobalContext();

	return (
		<div className={
			classNames(`${styles['email-signup-modal']} email-signup-modal-${emailSignUpFormStore.locationId}`, {
				[styles['email-signup-modal-no-image']]: isNoImageEmailSignUpModalContent
			})
		}>
			{
				(!isNoImageEmailSignUpModalContent) &&
					<EmailSignUpModalImage imageName={imageName} imageWidth={imageWidth} />
			}
			<div className={styles['email-signup-modal-content']}>
				<div className={styles['email-signup-modal-heading-container']}>
					{
						headingText &&
							<h1 className={`Heading Heading--3 ${styles['email-signup-modal-heading']}`}>
								{headingText}
							</h1>
					}

					{
						subheadingText &&
							<p className={styles['email-signup-modal-subheading']}>
								{subheadingText}
							</p>
					}
				</div>

				<div className="email-signup-modal-form-container">
					<EmailSignUpFormViewState emailSignUpFormStore={emailSignUpFormStore} />
				</div>
			</div>
		</div>
	);
});
