import Cookie from 'js-cookie';
import {
	action, computed, observable, makeObservable
} from 'mobx';
import React from 'react';

import { EmailSignUpModal } from '~/email-signup/Components/EmailSignUpModal';
import { EmailSignUpFormStoreFactory } from '~/email-signup/Stores/emailSignUpForm.store';
import {
	defaultEmailSignUpKey,
	emailSignUpModalTimeout,
} from '~/email-signup/emailSignUp.constants';
import {
	getEmailSignUpModalContentById,
	getEmailSignUpModalSourceById,
} from '~/email-signup/emailSignUp.utils';
import { PageStoreFactory } from '~/global/Stores/Page.store';

class EmailSignUpModalStore {
	emailSignUpModalId = '';

	emailSignUpModalSuccess = false;

	constructor() {
		makeObservable(this, {
			emailSignUpModalId: observable,
			emailSignUpModalSuccess: observable,
			isMediaQueryMd: computed,
			emailSignUpModalContent: computed,
			emailSignUpModalSource: computed,
			isNoImageEmailSignUpModalContent: computed,
			openEmailSignUpModal: action.bound,
			emailSignUpFormSuccessCallback: action.bound
		});
	}

	get isMediaQueryMd() {
		const pageStore = PageStoreFactory.create();
		return pageStore.isMediaQueryMd;
	}

	get emailSignUpModalContent() {
		return getEmailSignUpModalContentById(this.emailSignUpModalId);
	}

	get emailSignUpModalSource() {
		return getEmailSignUpModalSourceById(this.emailSignUpModalId);
	}

	get isNoImageEmailSignUpModalContent() {
		const { imageName } = this.emailSignUpModalContent;

		return !this.isMediaQueryMd || !imageName;
	}

	deferOrOpenEmailSignUpModal() {
		const monetateModalCookie = Cookie.get('rnb.MONETATE_MODAL');

		if (!monetateModalCookie) {
			if (this.magicModal.model.isOpen) {
				this.magicModal.model.onCloseModal = () => {
					setTimeout(this.openEmailSignUpModal, emailSignUpModalTimeout);
				};
			} else {
				this.openEmailSignUpModal();
			}
		}
	}

	openEmailSignUpModal() {
		const { modalWidth } = this.emailSignUpModalContent;

		const magicSpinnerProps = {
			isLoading: true,
			minHeight: '150px',
		};

		this.emailSignUpFormStore = EmailSignUpFormStoreFactory.create({
			emailSignUpFormSuccessCallback: this.emailSignUpFormSuccessCallback,
			hideLegend: true,
			locationId: this.emailSignUpModalId,
			magicSpinnerProps,
			source: this.emailSignUpModalSource,
			useInlineSubmitButton: this.isNoImageEmailSignUpModalContent,
		});

		this.emailSignUpModalSuccess = false;

		this.magicModal.openModal({
			accessibleTitle: 'Email Sign-Up',
			anchorBottom: !this.isMediaQueryMd,
			containerClass: 'monetate-modal',
			content: {
				children: (
					<EmailSignUpModal />
				),
			},
			dropShadow: !this.isMediaQueryMd,
			fullBleed: true,
			reverseCloseButton: this.isNoImageEmailSignUpModalContent,
			showOverlay: this.isMediaQueryMd,
			title: '',
			maxWidth: modalWidth,
		});

		Cookie.set('rnb.MONETATE_MODAL', 'PROACTIVE_EMAIL_SIGNUP');
	}

	emailSignUpFormSuccessCallback() {
		this.emailSignUpModalSuccess = true;
	}
}

export const EmailSignUpModalStoreFactory = {
	create: ({
		emailSignUpModalId = defaultEmailSignUpKey,
	} = {}, magicModal) => {
		const emailSignUpModalStore = new EmailSignUpModalStore();

		Object.assign(emailSignUpModalStore, {
			emailSignUpModalId,
			magicModal,
		});

		// global.emailSignUpModalStore = emailSignUpModalStore;

		return emailSignUpModalStore;
	},
};
