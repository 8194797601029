import { HERO_ID, SUBFEATURE_ID } from '~/features/Types/FeatureLibrary.type';
import { REGION } from '~/monetate/Types/Monetate.type';
import { VendorEventTrackingModel } from '~/tracking/vendor-events/common/Models/VendorEvent.tracking.model';
import { MonetateClickJSON } from '~/tracking/vendor-events/monetate/Interfaces/IMonetateEvent.tracking';

export class MonetateClickEventTrackingModel {
	monetateKey

	monetateType

	constructor(key: HERO_ID | SUBFEATURE_ID, type: REGION) {
		this.monetateKey = key;
		this.monetateType = type;
	}

	get trackingJson(): MonetateClickJSON {
		const vendorEventTrackingModel = new VendorEventTrackingModel();
		return {
			event: 'monetateclick',
			content: vendorEventTrackingModel.pageID,
			monetateKey: `${this.monetateKey}:${this.monetateType}`,
		};
	}
}
