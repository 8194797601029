'use client';

import { observer } from 'mobx-react';
import React from 'react';

export const StorageGiveawayModal = observer(({
	storageGiveawayModalStore: {
		storageGiveawayModalSuccessCallback,
		storageGiveawayModalComponent: StorageGiveawayModalComponent,
	}
}) => {
	return (
		<StorageGiveawayModalComponent clickHandler={storageGiveawayModalSuccessCallback} />
	);
});
