import classNames from 'classnames';
import React, { AnchorHTMLAttributes } from 'react';

export interface IProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
	href: string;
	printAsLink?: boolean;
}

export const LinkBase = (props: IProps) => {
	const {
		printAsLink = true,
		className = '',
		...anchorProps
	} = props;

	return (
		<a
			{...anchorProps}
			className={classNames(
				className,
				{
					'no-print-underline': !printAsLink,
				}
			)}
		>
			{props.children}
		</a>
	);
};
