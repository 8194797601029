import Cookie from 'js-cookie';
import {
	action, computed, observable, makeObservable
} from 'mobx';
import React from 'react';

import {
	defaultFDSKey,
	FDSModalComponents,
	FDSModalTimeout,
} from '~/free-design-services-modal/freeDesignServicesModal.constants';
import { FDSModal } from '~/free-design-services-modal/Components/freeDesignServicesModal';
import { FDSModalWrapper } from '~/components/magic-modal/Components/FDSModal';
import { PageStoreFactory } from '~/global/Stores/Page.store';

class FDSModalStore {
	fdsModalId = '';

	fdsModalSuccess = false;

	constructor() {
		makeObservable(this, {
			fdsModalId: observable,
			fdsModalSuccess: observable,
			fdsModalComponent: computed,
			isMediaQueryMd: computed,
			openFDSModal: action.bound,
			fdsModalSuccessCallback: action.bound
		});
	}

	get fdsModalComponent() {
		return FDSModalComponents[this.fdsModalId] || FDSModalComponents[defaultFDSKey];
	}

	get isMediaQueryMd() {
		const pageStore = PageStoreFactory.create();
		return pageStore.isMediaQueryMd;
	}

	deferOrOpenFDSModal() {
		const monetateModalCookie = Cookie.get('rnb.MONETATE_MODAL');

		if (!monetateModalCookie) {
			if (this.magicModal.model.isOpen) {
				this.magicModal.model.onCloseModal = () => {
					setTimeout(this.openFDSModal, FDSModalTimeout);
				};
			} else {
				this.openFDSModal();
			}
		}
	}

	openFDSModal() {
		this.magicModal.openModal({
			reverseCloseButton: false,
			WrapperComponent: FDSModalWrapper,
			flushSides: true,
			containerClass: 'monetate-modal',
			content: {
				children: (
					<FDSModal fdsModalStore={this} />
				),
			},
			title: false,
			maxWidth: 720,
		});

		Cookie.set('rnb.MONETATE_MODAL', 'FREE_DESIGN_SERVICES');
	}

	fdsModalSuccessCallback() {
		this.fdsModalSuccess = true;

		this.magicModal.closeModal();
	}
}

export const FDSModalStoreFactory = {
	create: ({
		fdsModalId = defaultFDSKey,
	} = {}, magicModal) => {
		const fdsModalStore = new FDSModalStore();

		Object.assign(fdsModalStore, {
			fdsModalId,
			magicModal,
		});

		// global.fdsModalStore = fdsModalStore;

		return fdsModalStore;
	},
};
