import Cookie from 'js-cookie';
import {
	action, computed, observable, makeObservable
} from 'mobx';
import React from 'react';

import { defaultSwatchOrderingKey, swatchOrderingModalComponents, swatchOrderingModalTimeout } from '~/swatch-ordering/swatchOrdering.constants';
import { SwatchOrderingModal } from '~/swatch-ordering/Components/SwatchOrderingModal';
import { SwatchOrderingModalWrapper } from '~/components/magic-modal/Components/SwatchOrderingModal';
import styles from '~/swatch-ordering/Components/SwatchOrderModalDefault.module.scss';

class SwatchOrderingModalStore {
	swatchOrderingModalId = '';

	swatchOrderingModalSuccess = false;

	constructor() {
		makeObservable(this, {
			swatchOrderingModalId: observable,
			swatchOrderingModalSuccess: observable,
			swatchOrderingModalComponent: computed,
			openSwatchOrderingModal: action.bound,
			swatchOrderingModalSuccessCallback: action.bound
		});
	}

	get swatchOrderingModalComponent() {
		return swatchOrderingModalComponents[this.swatchOrderingModalId] || swatchOrderingModalComponents[defaultSwatchOrderingKey];
	}

	deferOrOpenSwatchOrderingModal() {
		const monetateModalCookie = Cookie.get('rnb.MONETATE_MODAL');

		if (!monetateModalCookie) {
			if (this.magicModal.model.isOpen) {
				this.magicModal.model.onCloseModal = () => {
					setTimeout(this.openSwatchOrderingModal, swatchOrderingModalTimeout);
				};
			} else {
				this.openSwatchOrderingModal();
			}
		}
	}

	openSwatchOrderingModal() {
		this.magicModal.openModal({
			WrapperComponent: SwatchOrderingModalWrapper,
			flushSides: true,
			containerClass: 'monetate-modal',
			modalClassName: styles.swatchOrdering,
			content: {
				children: (
					<SwatchOrderingModal swatchOrderingModalStore={this} />
				),
			},
			title: false,
			width: '90vw',
			maxWidth: 600,
		});

		Cookie.set('rnb.MONETATE_MODAL', 'SWATCH_ORDERING');
	}

	swatchOrderingModalSuccessCallback() {
		this.swatchOrderingModalSuccess = true;

		this.magicModal.closeModal();
	}
}

export const SwatchOrderingModalStoreFactory = {
	create: ({
		swatchOrderingModalId = defaultSwatchOrderingKey,
	} = {}, magicModal) => {
		const swatchOrderingModalStore = new SwatchOrderingModalStore();

		Object.assign(swatchOrderingModalStore, {
			swatchOrderingModalId,
			magicModal,
		});

		// global.swatchOrderingModalStore = swatchOrderingModalStore;

		return swatchOrderingModalStore;
	},
};
