import React, { ComponentProps } from 'react';

import { TextLinkColor } from '../Types/constants';
import { buildTextLinkClassNames } from '../Utils/Button.utils';

import { LinkBase } from './LinkBase';

export interface IProps extends Omit<ComponentProps<typeof LinkBase>, 'color'> {
	color?: TextLinkColor;
	hasNoUnderline?: boolean;
}

export const TextLink = (props: IProps) => {
	const {
		color,
		hasNoUnderline = false,
		...anchorProps
	} = props;
	const className = buildTextLinkClassNames(color, hasNoUnderline);

	return (
		<LinkBase
			{...anchorProps}
			className={className}
		>
			{props.children}
		</LinkBase>
	);
};
