import { HERO_ID, SUBFEATURE_ID } from '~/features/Types/FeatureLibrary.type';
import { MonetateActionType, REGION } from '~/monetate/Types/Monetate.type';
import { MonetateClickJSON, MonetateLoadJSON, MonetateViewJSON } from '~/tracking/vendor-events/monetate/Interfaces/IMonetateEvent.tracking';
import { MonetateClickEventTrackingModel } from '~/tracking/vendor-events/monetate/Models/MonetateClickEvent.tracking.model';
import { MonetateLoadEventTrackingModel } from '~/tracking/vendor-events/monetate/Models/MonetateLoadEvent.tracking.model';
import { MonetateViewEventTrackingModel } from '~/tracking/vendor-events/monetate/Models/MonetateViewEvent.tracking.model';

export class MonetateTrackingStore {
	tsWindow = window as any;

	trackMonetateClick(key: HERO_ID | SUBFEATURE_ID | undefined, type: REGION | undefined) {
		if (!this.tsWindow.eventDataLayer || !key || !type) {
			return;
		}
		const monetateClickTrackingModel = new MonetateClickEventTrackingModel(key, type);

		this.pushToEventDataLayer(monetateClickTrackingModel.trackingJson);
	}

	trackMonetateView(key: HERO_ID | SUBFEATURE_ID | undefined, type: REGION | undefined) {
		if (!this.tsWindow.eventDataLayer || !key || !type) {
			return;
		}
		const monetateViewTrackingModel = new MonetateViewEventTrackingModel(key, type);

		this.pushToEventDataLayer(monetateViewTrackingModel.trackingJson);
	}

	trackMonetateLoad(actions: MonetateActionType[]) {
		if (!this.tsWindow.eventDataLayer || !actions.length) {
			return;
		}

		const monetateLoadTrackingModel = new MonetateLoadEventTrackingModel(actions);

		this.pushToEventDataLayer(monetateLoadTrackingModel.trackingJson);
	}

	pushToEventDataLayer(json: MonetateClickJSON | MonetateLoadJSON | MonetateViewJSON) {
		this.tsWindow.eventDataLayer.push(json);
	}
}
