import { FreeDesignServicesModalDefault } from '~/free-design-services-modal/Components/freeDesignServicesModalDefault';

const defaultFDSKey = 'DEFAULT';

const FDSModalComponents = {
	DEFAULT: FreeDesignServicesModalDefault,
};

const FDSModalTimeout = 1000;

export {
	defaultFDSKey,
	FDSModalComponents,
	FDSModalTimeout,
};
