import { computed, makeObservable } from 'mobx';

class AccountLink {
	constructor({
		globalDynamic,
		HREF,
		featureTogglesModel,
	} = {}) {
		makeObservable(this, {
			accountLinkText: computed,
			accountLinkUrl: computed,
		});

		Object.assign(this, {
			globalDynamic,
			HREF,
			featureTogglesModel,
		});
	}

	get accountLinkText() {
		const { logoutLink } = this.globalDynamic;

		return logoutLink ? 'My Account' : 'Sign In';
	}

	get accountLinkUrl() {
		const {
			loginPageLink = '/account/sign-in/index.ftl',
			logoutLink,
		} = this.globalDynamic;
		const url = this.featureTogglesModel.isOn('ACCOUNT_OVERVIEW') ? this.HREF.account.overview : this.HREF.account.profile;

		return logoutLink ? url : loginPageLink;
	}
}

export { AccountLink };
