import axios from 'axios';

import type { Monetate } from '~/monetate/Models/Monetate.model';

import {
	engineApiChannel,
	engineApiUrl,
	eventTypes,
} from '~/monetate/Monetate.constants';

const engineApiRequest = (postData = {}) => {
	return axios({
		data: JSON.stringify({
			...postData,
			channel: engineApiChannel,
		}),
		method: 'post',
		transformRequest: [
			(data, headers) => {
				headers.clear();
				headers.setContentType('application/json; charset=utf-8');

				return data;
			},
		],
		withCredentials: false,
		url: engineApiUrl,
	});
};

export const getDecision = (monetateModel: Monetate, events = []) => {
	return engineApiRequest({
		events: [
			{
				eventType: eventTypes.DECISION_REQUEST,
				requestId: monetateModel.requestId.toString(),
			},
			...events,
		],
		...Boolean(monetateModel.monetateId) && { monetateId: monetateModel.monetateId },
	});
};

export const recordImpressions = ({
	impressionIds = [],
	monetateId = '',
}) => {
	return engineApiRequest({
		events: [
			{
				eventType: eventTypes.RECORD_IMPRESSIONS,
				impressionIds,
			},
		],
		...Boolean(monetateId) && { monetateId },
	});
};

export const recordPageEvents = ({
	monetateId = '',
	pageEvents = [],
}) => {
	return engineApiRequest({
		events: [
			{
				eventType: eventTypes.RECORD_PAGE_EVENTS,
				pageEvents,
			},
		],
		...Boolean(monetateId) && { monetateId },
	});
};
