import { emailSignUpModalContents, emailSignUpSources } from '~/email-signup/emailSignUp.constants';

const getEmailSignUpModalContentById = (emailSignUpModalId = '') => {
	const lookedUpEmailSignUpModalContent = emailSignUpModalContents[emailSignUpModalId];

	return lookedUpEmailSignUpModalContent || emailSignUpModalContents.DEFAULT;
};

const getEmailSignUpModalSourceById = (emailSignUpModalId = '') => {
	const lookedUpEmailSignUpModalSource = emailSignUpSources[emailSignUpModalId];

	return lookedUpEmailSignUpModalSource || emailSignUpSources.MONETATE_MODAL;
};

export {
	getEmailSignUpModalContentById,
	getEmailSignUpModalSourceById,
};
