import { observable } from 'mobx';
import axios from 'axios';

import { isFunction } from '~/util/isFunction';
import { redirect } from '~/util/redirect';
import {
	rootRelativeDynaSitePath, isOnProductPage, isOnShop, sitePath,
} from '~/global/global.constants';
import { encodePassword } from '~/util/encodePassword';
import { cartChangedAction } from '~/cart/Actions/cartChanged.action';
import { ValidateAndAjaxSubmitPlugin } from '~/util/formz/plugins/ValidateAndAjaxSubmitPlugin';
import { FeatureTogglesModel } from '~/util/feature-toggles/Models/FeatureToggles.model';
import { AccountAccessEventsTrackingStoreFactory } from '~/tracking/account/access-events/Stores/AccountAccessEvents.tracking.store';

const signInSuccessCallback = (response, accountPageLink, callbackFromStore) => {
	if (isFunction(callbackFromStore)) {
		callbackFromStore();
		return;
	}
	if (isOnProductPage || isOnShop) {
		return;
	}
	if (!accountPageLink) {
		return;
	}
	const parsedUrl = new URL(`${sitePath}${accountPageLink}`);
	let relativePath = `${parsedUrl.pathname}${parsedUrl.search}`;

	// normalize the relative path. the original request path that axios forwards gets an additional "/m" prefix applied by akamai.
	if (relativePath.slice(0, 3) === '/m/') {
		relativePath = relativePath.slice(2, relativePath.length);
	}
	const result = `${rootRelativeDynaSitePath}${relativePath}`;

	redirect(result);
};

export const formModel = () => (
	observable({
		username: '',
		password: '',
		showPassword: false,
		rememberMe: false,
	})
);

export const formSettings = (store, contextImports) => {
	const {
		globalDynamicModel,
		globalDynamicStore,
		HREF,
	} = contextImports;
	return {
		id: 'login',
		reactProps: {
			method: 'POST',
		},
		settings: {
			plugins: [
				new ValidateAndAjaxSubmitPlugin(
					{
						ajaxSubmit: {
							submitHandler: (form) => {
								if (form.plugins.formValidator.hasErrors || !globalDynamicModel.loginLink) {
									return false;
								}
								const password = encodePassword(form.model.password);
								const loginData = [
									`username=${encodeURIComponent(form.model.username)}`,
									`password=${encodeURIComponent(password)}`,
									`remember-me=${encodeURIComponent(form.model.rememberMe)}`,
								];
								const loginSettings = {
									data: loginData.join('&'),
									method: 'post',
									headers: {
										'Content-Type': 'application/x-www-form-urlencoded',
									},
									url: globalDynamicModel.loginLink,
								};
								store.isSubmitProcessing = true;
								store.hasSubmitError = true;
								return axios(loginSettings);
							},
							promiseHandler: (promise) => {
								promise.then((response) => {
									store.isAuthenticated = true;
									store.isSubmitted = true;
									store.isSubmitProcessing = false;
									store.hasSubmitError = false;

									if (cartChangedAction) {
										if (cartChangedAction.getNumListeners()) {
											signInSuccessCallback(response, HREF.account.profile, store.callback);
											cartChangedAction.dispatch();
										}
									}

									globalDynamicStore.fetchData().then(() => {
										const {
											gpcSignal = false,
											customerTrackingKey = null,
										} = globalDynamicModel;

										const featureTogglesModel = new FeatureTogglesModel(globalDynamicModel);

										const accountEventsTrackingStore = AccountAccessEventsTrackingStoreFactory.create(featureTogglesModel);

										accountEventsTrackingStore.trackSignInEvent(customerTrackingKey);

										// handle GPC signal settings for OneTrust and Adobe
										store.oneTrustStore.handleGPCSignal(gpcSignal);

										if (cartChangedAction && !cartChangedAction.getNumListeners()) {
											const url = featureTogglesModel.isOn('ACCOUNT_OVERVIEW') ? HREF.account.overview : HREF.account.profile;
											signInSuccessCallback(response, url, store.callback);
										}
									});
								}, (error) => {
									store.isAuthenticated = false;
									store.isSubmitted = false;
									store.isSubmitProcessing = false;
									store.hasSubmitError = true;
									store.form?.fields?.username?.control?.controlRef?.focus?.();
									// Scroll up to make sure error is visible
									window.scrollTo(0, 0);
									return error;
								});
								return promise;
							},
						},
					},
				),
			],
		},
		fields: {
			username: {
				control: {
					reactProps: {
						type: 'email',
						disabled: '',
						size: 22,
						noValidate: true,
						required: true,
						autoComplete: 'email',
					},
				},
				label: {
					reactProps: {
						children: 'Email Address',
					},
				},
				settings: {
					lazyValidation: true,
					validationConstraints: {
						presence: {
							message: '^Enter your email address',
						},
						email: {
							message: '^Please enter a valid email address.',
						},
					},
				},
			},
			password: {
				control: {
					reactProps: {
						autoCapitalize: 'false',
						autoCorrect: 'false',
						spellCheck: 'false',
						size: 22,
						noValidate: true,
						required: true,
						autoComplete: 'current-password',
					},
				},
				label: {
					reactProps: {
						children: 'Password',
					},
				},
				settings: {
					validationConstraints: {
						presence: {
							message: '^Enter your password',
						},
						length: {
							minimum: 6,
							maximum: 256,
						},
					},
				},
			},
			showPassword: {
				reactProps: {
					className: 'show-password',
				},
				settings: {
					validationConstraints: {},
				},
			},
			rememberMe: {
				control: {
					reactProps: {
						type: 'checkbox',
					},
				},
				label: {
					reactProps: {
						children: 'Remember me',
					},
				},
				settings: {
					validationConstraints: {},
				},
			},
		},
	};
};
