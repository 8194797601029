import { monetateTagType } from '~/global/global.constants';

export const engineApiChannel = `a-d18aefa0/${monetateTagType}/roomandboard.com`;

export const engineApiUrl = 'https://engine.monetate.net/api/engine/v1/decide/roomandboard';

export const actionTypes = {
	OMNICHANNEL_JSON: 'monetate:action:OmnichannelJson',
	SOCIAL_PROOF: 'monetate:action:SocialProofDataV2',
};

export const eventTypes = {
	DECISION_REQUEST: 'monetate:decision:DecisionRequest',
	PAGE_VIEW: 'monetate:context:PageView',
	PRODUCT_DETAIL_VIEW: 'monetate:context:ProductDetailView',
	RECORD_IMPRESSIONS: 'monetate:record:Impressions',
	RECORD_PAGE_EVENTS: 'monetate:record:PageEvents',
};
