import { MonetateActionFactory, MonetateActionModel } from '~/monetate/Models/MonetateAction.model';
import { MonetateActionType } from '~/monetate/Types/Monetate.type';

class MonetateDecision {
	monetateActionModels

	requestId

	constructor(monetateActionModels: MonetateActionModel[], requestId: string) {
		this.monetateActionModels = monetateActionModels;
		this.requestId = requestId;
	}
}

export const MonetateDecisionModelFactory = {
	create: ({
		actions = [],
		requestId = '',
	}) => {
		const monetateActionModels = actions.map((actionData: MonetateActionType) => MonetateActionFactory.create(actionData));

		const monetateDecision = new MonetateDecision(monetateActionModels, requestId);

		return monetateDecision;
	},
};
