export const modelNamespace = {
	ROOT: 'RNB',
	get ACCOUNT_MANAGEMENT() {
		return `${this.ROOT}/Account/Management`;
	},
	get ACCOUNT_SIGN_IN() {
		return `${this.ROOT}/Account/SignIn`;
	},
	get BUSINESS() {
		return `${this.ROOT}/Business`;
	},
	get CALENDAR() {
		return `${this.ROOT}/Calendar`;
	},
	get CHECKOUT() {
		return `${this.ROOT}/Checkout`;
	},
	get CHLOROFORM() {
		return `${this.UTIL}/Chloroform`;
	},
	get CART() {
		return `${this.ROOT}/Cart`;
	},
	get CUSTOMER() {
		return `${this.ROOT}/Customer`;
	},
	get COMPONENT() {
		return `${this.ROOT}/Component`;
	},
	get DEV_BEAM() {
		return `${this.ROOT}/DevBeam`;
	},
	get EMAIL_SIGNUP() {
		return `${this.ROOT}/EmailSignup`;
	},
	get ENGAGE() {
		return `${this.ROOT}/Engage`;
	},
	get ENGAGE_TOOLBAR() {
		return `${this.ROOT}/EngageToolbar`;
	},
	get FAVORITES() {
		return `${this.ROOT}/Favorites`;
	},
	get MAILING_LIST() {
		return `${this.ROOT}/MailingList`;
	},
	get OPPORTUNITIES() {
		return `${this.ROOT}/Opportunities`;
	},
	get OVERVIEW() {
		return `${this.ROOT}/Overview`;
	},
	get PERSONALIZATION() {
		return `${this.ROOT}/Personalization`;
	},
	get PRODUCT_GROUP() {
		return `${this.ROOT}/ProductGroup`;
	},
	get PRODUCT_PAGE() {
		return `${this.ROOT}/ProductPage`;
	},
	get REVIEWS() {
		return `${this.ROOT}/Reviews`;
	},
	get SITE_MESSAGING() {
		return `${this.ROOT}/SiteMessaging`;
	},
	get UTIL() {
		return `${this.ROOT}/Util`;
	},
	get QUOTES() {
		return `${this.ROOT}/Quotes`;
	},
} as const;
