import Cookie from 'js-cookie';
import React from 'react';

import {
	s7ImagePath,
} from '~/global/global.constants';
import { LazyPicture } from '~/util/Components/LazyPicture';
import { TextLink } from '~/components/Buttons/Components/TextLink';

export const OutdoorGiveawayModalDefault = (props) => {
	const {
		clickHandler,
	} = props;

	function handleClick() {
		Cookie.set('rnb.OUTDOOR_GIVEAWAY_ENTERED', true, { expires: 30 });

		if (clickHandler) {
			clickHandler();
		}
	}

	return (
		<div>
			<div className="tw-block md:tw-flex tw-items-end tw-gap-2">
				<div className="">
					<LazyPicture
						mediumSrcSet={`${s7ImagePath}/outdoorGiveaway_0423?wid=270, ${s7ImagePath}/outdoorGiveaway_0423?wid=540 2x`}
						smallSrcSet={`${s7ImagePath}/outdoorGiveaway_0423_sm?wid=355, ${s7ImagePath}/outdoorGiveaway_0423_sm?wid=710 2x`}
						mediumSrcDimensions={[290, 450]}
						smallSrcDimensions={[355, 345]} />
				</div>
				<div className="md:tw-w-1/2 tw-w-full md:tw-p-0 tw-p-3">
					<h2 className="tw-heading-3 tw-mb-1">Ready. Set. Sun.</h2>
					<span className="tw-heading-5 tw-font-semibold tw-mb-3 tw-block">Outdoor Giveaway</span>
					<p>Dive into the season. We’re giving away an outdoor oasis to one lucky winner! Enter for a chance to win now through May 14.* </p>
					<p>The made-for-relaxation prize package includes two Emmet lounge chairs with cushions and a 9’ Oahu patio umbrella.</p>
					<a className="HollowButton HollowButton--gray tw-font-semibold" href="https://go.roomandboard.com/1DF34E" onClick={handleClick} target="_blank" rel="noreferrer">Enter Now</a>
					<p className="tw-mt-4 md:tw-mb-6 tw-mb-2 tw-text-2xs tw-text-gray-300">*One entry per person. Odds of winning depend on number of entries. Void where prohibited. No purchase necessary. See <TextLink href="https://go.roomandboard.com/C64EF0" target="_blank"><span className="tw-text-2xs">Official Rules</span></TextLink> for full details and AMOE.</p>
				</div>
			</div>
		</div>
	);
};
