'use client';

import React from 'react';
import { observer } from 'mobx-react';

import { StandardModal } from '~/components/magic-modal/Components/StandardModal';

export const StorageGiveawayModalWrapper = observer((props) => {
	return (
		<StandardModal className="storage-giveaway" store={props.store} />
	);
});
