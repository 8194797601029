'use client';

import React from 'react';
import { observer } from 'mobx-react';

const SwatchOrderingModal = observer(class SwatchOrderingModal extends React.Component {
	render() {
		const {
			swatchOrderingModalStore: {
				swatchOrderingModalSuccessCallback,
				swatchOrderingModalComponent: SwatchOrderingModalComponent,
			}
		} = this.props;

		return (
			<div>
				<SwatchOrderingModalComponent clickHandler={swatchOrderingModalSuccessCallback} />
			</div>
		);
	}
});

export { SwatchOrderingModal };
