import classNames from 'classnames';

import {
	ArrowButtonDirection, ArrowButtonSize, ButtonColor, ButtonVariant, TextLinkColor,
} from '~/components/Buttons/Types/constants';
import styles from '~/util/curalate/Components/button-arrow.module.scss';

export const getButtonVariant = (variant?: ButtonVariant): ButtonVariant => {
	return variant || ButtonVariant.Primary;
};

// eslint-disable-next-line default-param-last
export const buildButtonClassNames = (variant: ButtonVariant, color?: ButtonColor, isTransparent: boolean = false, direction?: ArrowButtonDirection, size?: ArrowButtonSize): string => {
	return classNames({
		[variant]: true,
		[`${variant}--${color}`]: color,
		[`${variant}--transparent`]: isTransparent,
		[styles.ButtonArrow]: variant === ButtonVariant.Arrow,
		[styles.ButtonArrowTransparent]: isTransparent && variant === ButtonVariant.Arrow,
		[styles.ButtonArrowUp]: direction && direction === ArrowButtonDirection.Up,
		[styles.ButtonArrowDown]: direction && direction === ArrowButtonDirection.Down,
		[styles.ButtonArrowLeft]: direction && direction === ArrowButtonDirection.Left,
		[styles.ButtonArrowRight]: direction && direction === ArrowButtonDirection.Right,
		[styles.ButtonArrowSmall]: size === ArrowButtonSize.Small,
		[styles.ButtonArrowMedium]: size === ArrowButtonSize.Medium,
	});
};

export const buildTextLinkClassNames = (color?: TextLinkColor, hasNoUnderline: boolean = false): string => {
	return classNames({
		'ButtonAnchor': true,
		'ButtonAnchor--red': color === TextLinkColor.Red,
		'ButtonAnchor--gray': color === TextLinkColor.Gray,
		'ButtonAnchor--noUnderline': hasNoUnderline,
	});
};
