import { FunctionComponent } from 'react';

import type { Monetate } from '~/monetate/Models/Monetate.model';
import type { MonetateStore } from '~/monetate/Stores/Monetate.store';
import { HERO_ID, SUBFEATURE_ID } from '~/features/Types/FeatureLibrary.type';

export enum DISPLAY_TYPE {
	FEATURE = 'FEATURE',
	MODAL = 'MODAL',
	PRODUCT_SUMMARY = 'PRODUCT_SUMMARY',
	SOCIAL_PROOF = 'SOCIAL_PROOF',
}

export enum REGION {
	HERO = 'HERO',
	SUBFEATURE = 'SUBFEATURE',
	BANNER = 'BANNER',
}

export type MonetateStoreType = MonetateStore | {};

export type MonetateModelType = Monetate | {};

export interface MonetateActionJson {
	apiEventKey?: string,
	component: HERO_ID | SUBFEATURE_ID | string;
	displayType: DISPLAY_TYPE,
	region?: REGION,
	timer?: number,
}

export interface MonetateAction<TJson extends object = MonetateActionJson> {
	actionId?: number,
	actionType?: string,
	feature: FunctionComponent<any>,
	impressionId?: string,
	json: TJson,
	message?: string,
	socialProofData?: any,
}

export interface MonetateActionHero extends MonetateAction {
	json: {
		apiEventKey: string,
		component: HERO_ID;
		displayType: DISPLAY_TYPE,
		region: REGION.HERO,
	}
}

export interface MonetateActionSubfeature extends MonetateAction {
	json: {
		apiEventKey: string,
		component: SUBFEATURE_ID;
		displayType: DISPLAY_TYPE,
		region: REGION.SUBFEATURE,
	}
}

export interface DefaultHero extends MonetateAction {
	json: {
		component: HERO_ID;
		displayType: DISPLAY_TYPE,
		region: REGION.HERO,
		apiEventKey: undefined,
	}
}

export interface DefaultSubfeature extends MonetateAction {
	json: {
		component: SUBFEATURE_ID;
		displayType: DISPLAY_TYPE,
		region: REGION.SUBFEATURE,
		apiEventKey: undefined,
	}
}

export type MonetateActionType =
	| MonetateAction<any>
	| MonetateActionHero
	| MonetateActionSubfeature
	| DefaultHero
	| DefaultSubfeature;

export const isMonetateStore = (
	store: MonetateStoreType | undefined,
): store is MonetateStore => {
	return store !== undefined && 'monetateModel' in store;
};

export const isMonetateModel = (
	model: MonetateModelType,
): model is Monetate => {
	return 'monetateId' in model;
};
