import Cookies from 'js-cookie';
import { action, makeObservable } from 'mobx';

import type { Monetate } from '~/monetate/Models/Monetate.model';

import { isOnServer, monetateEnabled } from '~/global/global.constants';
import { getDecision, recordImpressions, recordPageEvents } from '~/monetate/Monetate.api';
import { eventTypes } from '~/monetate/Monetate.constants';
import { MonetateDecisionModelFactory } from '~/monetate/Models/MonetateDecision.model';
import { HERO_ID, PAGE_TYPE, SUBFEATURE_ID } from '~/features/Types/FeatureLibrary.type';
import {
	isMonetateModel,
	MonetateModelType,
	REGION,
} from '~/monetate/Types/Monetate.type';
import { MonetateTrackingStore } from '~/tracking/vendor-events/monetate/Stores/MonetateEvent.tracking.store';

export class MonetateStore {
	monetateModel;

	monetateTrackingStore;

	constructor(monetateModel: Monetate) {
		makeObservable(this, {
			getDecisionData: action,
			setPageType: action,
		});
		this.monetateModel = monetateModel;
		this.monetateTrackingStore = new MonetateTrackingStore();
	}

	getDecisionData(events: any = []) {
		if (!isMonetateModel(this.monetateModel)) {
			return null;
		}
		return getDecision(this.monetateModel, events)
			.then(({
				data = {},
				data: {
					data: {
						responses = [],
					} = {},
					meta: {
						monetateId: newMonetateId = '',
					} = {},
				} = {},
			}) => {
				const newMonetateDecisionModels = responses.map((decisionData = {}) => MonetateDecisionModelFactory.create(decisionData));

				const monetateIdToUse = newMonetateId || this.monetateModel.monetateId;

				Cookies.set('mt.v', monetateIdToUse);

				this.monetateModel.addMonetateDecisionModels([...newMonetateDecisionModels]);

				Object.assign(this.monetateModel, {
					monetateId: monetateIdToUse,
					requestId: this.monetateModel.requestId + 1,
				});

				this.monetateModel.hasMonetateDecision = true;

				this.monetateTrackingStore.trackMonetateLoad(this.monetateModel.monetateActionModels);

				return data;
			})
			.catch((error) => {
				console.error(error);
				this.monetateModel.hasMonetateError = true;
			});
	}

	// eslint-disable-next-line default-param-last
	recordImpressions(impressionIds = [], componentId: HERO_ID | SUBFEATURE_ID, region: REGION) {
		const { monetateId = '' } = this.monetateModel;

		this.monetateTrackingStore.trackMonetateView(componentId, region);

		recordImpressions({
			impressionIds,
			monetateId,
		});
	}

	// eslint-disable-next-line default-param-last
	recordPageEvents(pageEvents = [], componentId: HERO_ID | SUBFEATURE_ID, region: REGION) {
		const { monetateId = '' } = this.monetateModel;

		this.monetateTrackingStore.trackMonetateClick(componentId, region);

		recordPageEvents({
			monetateId,
			pageEvents,
		});
	}

	recordPageView() {
		const {
			pageType = '',
			path = '',
			url = '',
			userAgent = '',
		} = this.monetateModel;

		this.monetateModel.setMonetateDecisionModels([]);

		this.monetateModel.requestId = 0;

		return this.getDecisionData([
			{
				eventType: eventTypes.PAGE_VIEW,
				...Boolean(pageType) && { pageType },
				path,
				url,
				userAgent,
			},
		]);
	}

	recordProductDetailView({
		breadcrumbs = [],
		products = [],
	}) {
		const {
			pageType = '',
			path = '',
			url = '',
			userAgent = '',
		} = this.monetateModel;

		return this.getDecisionData([
			{
				breadcrumbs,
				eventType: eventTypes.PAGE_VIEW,
				...Boolean(pageType) && { pageType },
				path,
				url,
				userAgent,
			},
			{
				eventType: eventTypes.PRODUCT_DETAIL_VIEW,
				products,
			},
		]);
	}

	setPageType(pageType: PAGE_TYPE) {
		this.monetateModel.pageType = pageType;
	}
}

export const MonetateStoreFactory = {
	create: (monetateModel: MonetateModelType) => {
		if (!monetateEnabled || !isMonetateModel(monetateModel)) {
			return {};
		}

		const monetateStore = new MonetateStore(monetateModel);

		if (!isOnServer && monetateModel.paramMonetateEnabled) {
			monetateStore.recordPageView();
		}

		return monetateStore;
	},
};
