import { DISPLAY_TYPE, type MonetateActionType } from '~/monetate/Types/Monetate.type';

export class MonetateActionModel {
	actionId;

	actionType;

	component;

	feature;

	impressionId;

	isControl;

	json;

	message;

	socialProofData;

	constructor({
		actionType,
		json,
		feature,
		impressionId,
		message,
		socialProofData,
	}: MonetateActionType) {
		this.actionId = 0;
		this.actionType = actionType;
		this.component = json?.component;
		this.feature = feature;
		this.impressionId = impressionId;
		this.isControl = false;
		this.json = socialProofData ? { displayType: DISPLAY_TYPE.SOCIAL_PROOF } : json;
		this.message = message;
		this.socialProofData = socialProofData;
	}
}

export const MonetateActionFactory = {
	create: (action: MonetateActionType) => {
		const monetateAction = new MonetateActionModel(action);

		return monetateAction;
	},
};
