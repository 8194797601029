import {
	action, computed, observable, toJS, makeObservable, set as mobxSet,
} from 'mobx';
import cn from 'classnames';

import { isOnServer } from '~/global/global.constants';
import { FieldBuilder } from '~/util/formz/builders/fieldBuilders';

class FormBuilder {
	id = null;

	// For debugging purposes to help find multiple instances of a form
	uniqueId;

	fields = {};

	model = {};

	reactPropsMap = null;

	constructor(baseModel, settings) {
		makeObservable(this, {
			id: observable,
			fields: observable,
			model: observable,
			reactPropsMap: observable,
			reactProps: computed,
			updateModelValue: action,
		});

		this.uniqueId = `${new Date() * Math.random()}`;
		this.model = observable(baseModel);
		this.id = observable.box(settings.id);
		this.reactPropsMap = observable.map({
			noValidate: true,
			id: settings.id,
			key: settings.id,
			className: cn(`formz ${settings.id}`, {
				[settings.baseId]: Boolean(settings.baseId),
			}),
			...settings.reactProps,
		});
		if (!settings.fields) {
			console.error(`You need to add fields to form ${this.id}`);
		}
		Object.keys(baseModel).forEach((key) => {
			if (settings?.fields?.[key]) {
				mobxSet(this.fields, key, new FieldBuilder(key, settings.fields[key], this));
			} else if (!isOnServer) {
				console.warn(`Form is missing field entry for: ${key}`);
			}
		});

		const plugins = settings?.settings?.plugins || [];

		plugins.forEach((pluginObj) => {
			if (pluginObj.setForm) {
				pluginObj.setForm(this);
				this.plugins = this.plugins || {};
				this.plugins[pluginObj.id] = pluginObj;
			}
		});
	}

	get reactProps() {
		return {
			onSubmit: this.formSubmit,
			...Object.fromEntries(toJS(this.reactPropsMap)),
		};
	}

	updateModelValue(key, value) {
		this.model[key] = value;
	}
}

export { FormBuilder };
