import { OutdoorGiveawayModalDefault } from '~/outdoor-giveaway-modal/Components/OutdoorGiveawayModalDefault';

const defaultOutdoorGiveawayKey = 'DEFAULT';

const outdoorGiveawayModalComponents = {
	DEFAULT: OutdoorGiveawayModalDefault,
};

const outdoorGiveawayModalTimeout = 1000;

export {
	defaultOutdoorGiveawayKey,
	outdoorGiveawayModalComponents,
	outdoorGiveawayModalTimeout,
};
