'use client';

import React, {
	createContext, useContext, ReactNode, useRef,
} from 'react';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';

import type { MenuRegistrationRecord } from '~/components/flyout-menu/useForbidMultipleOpenMenus';
import type { AppConfigPageProps } from '~/global/app-config/AppConfig.types';
import { isOnServer } from '~/global/global.constants';
import { GlobalModelFactory } from '~/global/Models/Global.model';
import { MonetateStoreFactory } from '~/monetate/Stores/Monetate.store';
import { createMonetateModel } from '~/monetate/Helpers/Monetate.init';
import { ContactUsFormStoreFactory } from '~/contact-us/Stores/contactUsForm.store';
import { MonetateModelType } from '~/monetate/Types/Monetate.type';

export type GlobalContextProps = {
	children: ReactNode
	mockContext?: any,
	pageProps: AppConfigPageProps,
};

const globalContext = createContext<any>({});

export const GlobalContextProvider = ({ children, pageProps }: GlobalContextProps) => {
	const { pageType } = pageProps;
	const { personalizationScope } = pageProps;
	const menus = useRef<MenuRegistrationRecord[]>([]);
	const pathname = usePathname();
	const router = useRouter();
	const searchParams = useSearchParams();

	const globalModel = GlobalModelFactory.create({
		// @ts-ignore
		pageProps,
		// @ts-ignore
		pathname,
		router,
		searchParams,
	});
	const { featureTogglesModel = {} } = globalModel;
	const monetateAPIModel: MonetateModelType = !isOnServer && !featureTogglesModel.isOn('MONETATE_DISABLED') ? createMonetateModel({ pageType }) : {};
	const monetateAPIStore = !featureTogglesModel.isOn('MONETATE_DISABLED') ? MonetateStoreFactory.create(monetateAPIModel) : {};
	const contactUsFormStore = ContactUsFormStoreFactory.create();
	const value = Object.assign(globalModel, {
		menus,
		monetateAPIModel,
		monetateAPIStore,
		contactUsFormStore,
		personalizationScope,
	});

	return (
		<globalContext.Provider value={value}>
			{children}
		</globalContext.Provider>
	);
};

export const useGlobalContext = () => useContext(globalContext);
