import Cookie from 'js-cookie';
import {
	action, computed, observable, makeObservable
} from 'mobx';
import React from 'react';

import {
	defaultStorageGiveawayKey,
	storageGiveawayModalComponents,
	storageGiveawayModalTimeout,
} from '~/storage-giveaway-modal/storageGiveawayModal.constants';
import { StorageGiveawayModal } from '~/storage-giveaway-modal/Components/StorageGiveawayModal';
import { StorageGiveawayModalWrapper } from '~/components/magic-modal/Components/StorageGiveawayModal';

class StorageGiveawayModalStore {
	storageGiveawayModalId = '';

	storageGiveawayModalSuccess = false;

	constructor() {
		makeObservable(this, {
			storageGiveawayModalId: observable,
			storageGiveawayModalSuccess: observable,
			storageGiveawayModalComponent: computed,
			openStorageGiveawayModal: action.bound,
			storageGiveawayModalSuccessCallback: action.bound
		});
	}

	get storageGiveawayModalComponent() {
		return storageGiveawayModalComponents[this.storageGiveawayModalId] || storageGiveawayModalComponents[defaultStorageGiveawayKey];
	}

	deferOrOpenStorageGiveawayModal() {
		const monetateModalCookie = Cookie.get('rnb.MONETATE_MODAL');

		if (!monetateModalCookie) {
			if (this.magicModal.model.isOpen) {
				this.magicModal.model.onCloseModal = () => {
					setTimeout(this.openStorageGiveawayModal, storageGiveawayModalTimeout);
				};
			} else {
				this.openStorageGiveawayModal();
			}
		}
	}

	openStorageGiveawayModal() {
		this.magicModal.openModal({
			reverseCloseButton: false,
			WrapperComponent: StorageGiveawayModalWrapper,
			flushSides: true,
			containerClass: 'monetate-modal',
			content: {
				children: (
					<StorageGiveawayModal storageGiveawayModalStore={this} />
				),
			},
			title: false,
			maxWidth: 660,
		});

		Cookie.set('rnb.MONETATE_MODAL', 'STORAGE_GIVEAWAY');
	}

	storageGiveawayModalSuccessCallback() {
		this.storageGiveawayModalSuccess = true;

		this.magicModal.closeModal();
	}
}

export const StorageGiveawayModalStoreFactory = {
	create: ({
		storageGiveawayModalId = defaultStorageGiveawayKey,
	} = {}, magicModal = {}) => {
		const storageGiveawayModalStore = new StorageGiveawayModalStore();

		Object.assign(storageGiveawayModalStore, {
			storageGiveawayModalId,
			magicModal,
		});

		// global.storageGiveawayModalStore = storageGiveawayModalStore;

		return storageGiveawayModalStore;
	},
};
