import {
	action, computed, makeObservable, observable,
} from 'mobx';

import { PAGE_TYPE } from '~/features/Types/FeatureLibrary.type';
import {
	isMonetateModel,
	MonetateActionType,
	MonetateModelType,
} from '~/monetate/Types/Monetate.type';

import { groupByFunction } from '~/util/groupBy';

export class Monetate {
	hasMonetateDecision = false;

	hasMonetateError = false;

	monetateDecisionModels: any = undefined;

	monetateId;

	pageType;

	paramMonetateEnabled;

	path;

	requestId;

	url;

	userAgent;

	constructor(settings: any) {
		makeObservable(this, {
			hasMonetateDecision: observable,
			hasMonetateError: observable,
			monetateDecisionModels: observable,
			setMonetateDecisionModels: action.bound,
			addMonetateDecisionModels: action.bound,
			monetateActionModels: computed,
			monetateActionModelsByDisplayType: computed,
		});
		this.setMonetateDecisionModels(settings.monetateDecisionModels);
		this.monetateId = settings.monetateId || '';
		this.pageType = settings.pageType;
		this.path = settings.path;
		this.requestId = settings.requestId || 0;
		this.url = settings.url;
		this.userAgent = settings.userAgent;
		this.paramMonetateEnabled = settings.paramMonetateEnabled;
	}

	get monetateActionModels(): MonetateActionType[] {
		return this.monetateDecisionModels.reduce((accumulatedMonetateActionModels: MonetateActionType[], { monetateActionModels = [] }) => {
			return [...accumulatedMonetateActionModels, ...monetateActionModels];
		}, []);
	}

	get monetateActionModelsByDisplayType(): any {
		return groupByFunction(this.monetateActionModels, ({
			json: {
				displayType = '',
			} = {},
		}) => displayType);
	}

	setMonetateDecisionModels(monetateDecisionModels: any) {
		this.monetateDecisionModels = monetateDecisionModels;
	}

	addMonetateDecisionModels(monetateDecisionModels: any[]) {
		this.monetateDecisionModels.push(...monetateDecisionModels);
	}
}

export const MonetateModelFactory = {
	create: ({
		monetateDecisionModels = [],
		monetateId = '',
		pageType = PAGE_TYPE.MISSING,
		paramMonetateEnabled = undefined,
		path = '',
		requestId = 0,
		url = '',
		userAgent = '',
	} = {}) => {
		const settings: MonetateModelType = {
			monetateDecisionModels,
			monetateId,
			pageType,
			paramMonetateEnabled,
			path,
			requestId,
			url,
			userAgent,
		};

		if (isMonetateModel(settings)) {
			const monetate = new Monetate(settings);

			return monetate;
		}
		return {};
	},
};
