import React from 'react';

import { sortBy } from '~/util/sortBy';
import { isString } from '~/util/isString';
import { isEngage, rootRelativeDynaSitePath } from '~/global/global.constants';

const tzErrorMsg = 'We cannot determine your specific delivery zone. Please contact a Design Associate at 800.301.9720 within the United States, or +001 612.234.3049 internationally.';
const invalidGiftCardNumberOrPin = 'Please enter a valid card number/PIN combination.';

export const GIFT_CARD_MESSAGING = {
	INVALID: invalidGiftCardNumberOrPin,
	CARD_NUMBER_PRESENCE: 'Gift Card number is required.',
	CARD_PIN_PRESENCE: 'PIN is required.',
};

/**
 * These messages will be matched to the server errors.
 * These can be strings or functions that take an object with label and value.
 * You can return a function for the message which will be rendered as a React Component.
 */
const SERVER_SIDE_VALIDATION_MESSAGING = {
	customerHasAccount: 'An account already exists for this customer.',
	usernameUniqueConstraintError() {
		const message = 'An account already exists for this email address.';

		return () => {
			if (isEngage) {
				return null;
			}
			return (
				<div>
					{`${message} `}
					<a data-ignore-blur-validation href={`${rootRelativeDynaSitePath}/account/reset-password/`}>Forgot your password?</a>
				</div>
			);
		};
	},
	invalidEmail: 'Email address is invalid.',
	invalidEmailError: 'Email address is invalid.',
	required: (value: any) => `${value.label} is required.`,
	improperFieldLength: (value: any) => `${value.label} is too long.`,
	incorrect: (value: any) => `The ${value.label.toLowerCase()} entered is incorrect.`,
	notFound: (value: any) => `The ${value.label.toLowerCase()} was not found.`,
	usernameNotFound: 'The email entered was not found.',
	oldPasswordMismatch: 'The password entered does not match your current password.',
	usernameOrPasswordNotFound: 'The username or password is incorrect',
	// zipcode does not exist from smarty streets response
	zipCodeNotFound: tzErrorMsg,
	// regex failed (client-side validation should take care this)
	zipCodeInvalidFormat: 'Please enter a valid US ZIP code',
	// tz is forbidden
	transportationZoneForbidden: tzErrorMsg,
	engageAccessDenied: 'Access denied for this username',
	engageLdapCommunicationException: 'Our site is experiencing problems. Please call 800.301.9720.',
	salesforceInvalidEmail: isEngage ? 'Please enter a valid email. If the problem persists, please open a Help Desk ticket.' : 'Please enter a valid email.',
};
const selectOptionsConstants = {
	// @ts-ignore
	reactOptionMapper(type: any) {
		// @ts-ignore
		const collection = isString(type) ? selectOptionsConstants[type] : type;

		return collection.map((optionData: any) => {
			return (
				<option key={type + optionData.value} value={optionData.value}>{optionData.text}</option>
			);
		});
	},
	phoneTypes: [
		{ text: '', value: null },
		{ text: 'Cell', value: 'CELL' },
		{ text: 'Home', value: 'HOME' },
		{ text: 'Work', value: 'WORK' },
	],
	get phoneTypesNoBlank() {
		return selectOptionsConstants.phoneTypes.slice(1);
	},
	customerType: [
		{ text: 'End user of products', value: 'End User', description: 'I purchase items for use in my business.' },
		{ text: 'Designer/specifier', value: 'Specifier', description: 'I select and recommend items to meet my customer’s design or project needs.' },
		{ text: 'Purchasing agent/dealer', value: 'Purchasing Agent', description: 'I purchase items on behalf of other businesses/organizations.' },
	],
	industryTypes: [
		{ text: 'Select Industry', value: '' },
		{ text: 'Architecture', value: 'architecture' },
		{ text: 'Education', value: 'education' },
		{ text: 'Healthcare', value: 'healthcare' },
		{ text: 'Hospitality', value: 'hospitality' },
		{ text: 'Interior Design', value: 'intertior-design' },
		{ text: 'Real Estate/Developer', value: 'real-estate-developer' },
		{ text: 'Retail', value: 'retail' },
	],
	industryTypeBusiness: [
		{ text: 'Select Industry', value: '' },
		{ text: 'Agriculture', value: 'Agriculture' },
		{ text: 'Architects/Designers', value: 'Architects/Designers' },
		{ text: 'Building Operation Support', value: 'Building Operation Support' },
		{ text: 'Business Services', value: 'Business Services' },
		{ text: 'Construction', value: 'Construction' },
		{ text: 'Education', value: 'Education' },
		{ text: 'Entertainment', value: 'Entertainment' },
		{ text: 'Finance/Insurance', value: 'Finance/Insurance' },
		{ text: 'Health Care', value: 'Health Care' },
		{ text: 'Hotels', value: 'Hotels' },
		{ text: 'Legal', value: 'Legal' },
		{ text: 'Manufacturing', value: 'Manufacturing' },
		{ text: 'Media', value: 'Media' },
		{ text: 'Mining', value: 'Mining' },
		{ text: 'Museums', value: 'Museums' },
		{ text: 'Personal Services', value: 'Personal Services' },
		{ text: 'Public Administration', value: 'Public Administration' },
		{ text: 'Real Estate', value: 'Real Estate' },
		{ text: 'Recreation/Sports', value: 'Recreation/Sports' },
		{ text: 'Religious Organizations', value: 'Religious Organizations' },
		{ text: 'Restaurants', value: 'Restaurants' },
		{ text: 'Retail', value: 'Retail' },
		{ text: 'Science and Technology', value: 'Science and Technology' },
		{ text: 'Transportation/Warehousing', value: 'Transportation/Warehousing' },
		{ text: 'Utilities', value: 'Utilities' },
		{ text: 'Wholesale Trade', value: 'Wholesale Trade' },
	],
	usStates: [
		{ text: '', value: '' },
		{ text: 'AL', value: 'AL' },
		{ text: 'AK', value: 'AK' },
		{ text: 'AZ', value: 'AZ' },
		{ text: 'AR', value: 'AR' },
		{ text: 'CA', value: 'CA' },
		{ text: 'CO', value: 'CO' },
		{ text: 'CT', value: 'CT' },
		{ text: 'DE', value: 'DE' },
		{ text: 'DC', value: 'DC' },
		{ text: 'FL', value: 'FL' },
		{ text: 'GA', value: 'GA' },
		{ text: 'HI', value: 'HI' },
		{ text: 'ID', value: 'ID' },
		{ text: 'IL', value: 'IL' },
		{ text: 'IN', value: 'IN' },
		{ text: 'IA', value: 'IA' },
		{ text: 'KS', value: 'KS' },
		{ text: 'KY', value: 'KY' },
		{ text: 'LA', value: 'LA' },
		{ text: 'ME', value: 'ME' },
		{ text: 'MD', value: 'MD' },
		{ text: 'MA', value: 'MA' },
		{ text: 'MI', value: 'MI' },
		{ text: 'MN', value: 'MN' },
		{ text: 'MS', value: 'MS' },
		{ text: 'MO', value: 'MO' },
		{ text: 'MT', value: 'MT' },
		{ text: 'NE', value: 'NE' },
		{ text: 'NV', value: 'NV' },
		{ text: 'NH', value: 'NH' },
		{ text: 'NJ', value: 'NJ' },
		{ text: 'NM', value: 'NM' },
		{ text: 'NY', value: 'NY' },
		{ text: 'NC', value: 'NC' },
		{ text: 'ND', value: 'ND' },
		{ text: 'OH', value: 'OH' },
		{ text: 'OK', value: 'OK' },
		{ text: 'OR', value: 'OR' },
		{ text: 'PA', value: 'PA' },
		{ text: 'RI', value: 'RI' },
		{ text: 'SC', value: 'SC' },
		{ text: 'SD', value: 'SD' },
		{ text: 'TN', value: 'TN' },
		{ text: 'TX', value: 'TX' },
		{ text: 'UT', value: 'UT' },
		{ text: 'VT', value: 'VT' },
		{ text: 'VA', value: 'VA' },
		{ text: 'WA', value: 'WA' },
		{ text: 'WV', value: 'WV' },
		{ text: 'WI', value: 'WI' },
		{ text: 'WY', value: 'WY' },
	],
	get continentalUsStates() {
		return selectOptionsConstants.usStates.filter(state => state.value !== 'AK' && state.value !== 'HI');
	},
	get billingStates() {
		const result = [...selectOptionsConstants.usStates];

		if (!result.some(obj => obj.value === 'AK')) {
			result.push({
				text: 'AK',
				value: 'AK'
			});
		}
		if (!result.some(obj => obj.value === 'HI')) {
			result.push({
				text: 'HI',
				value: 'HI'
			});
		}
		if (!result.some(obj => obj.value === 'PR')) {
			result.push({
				text: 'PR',
				value: 'PR'
			});
		}
		return sortBy(result, 'text');
	},
};

export {
	SERVER_SIDE_VALIDATION_MESSAGING,
	selectOptionsConstants,
};
