import Cookies from 'js-cookie';

import { PAGE_TYPE } from '~/features/Types/FeatureLibrary.type';
import type { MonetateModelType } from '~/monetate/Types/Monetate.type';
import { MonetateModelFactory } from '~/monetate/Models/Monetate.model';

export const createMonetateModel = ({ pageType = PAGE_TYPE.MISSING }: { pageType: PAGE_TYPE | undefined}) => {
	const monetateId = Cookies.get('mt.v') || '';

	const {
		href: url = '',
		pathname: path = '',
		search,
	} = window.location;

	const urlParams = new URLSearchParams(search);

	const paramMonetateEnabled = urlParams.get('monetateEnabled') !== 'false';

	const { userAgent = '' } = window.navigator;

	const monetateSettings: MonetateModelType = {
		monetateId,
		pageType,
		paramMonetateEnabled,
		path,
		url,
		userAgent,
	};

	const monetateModel = MonetateModelFactory.create(monetateSettings);

	return monetateModel;
};
