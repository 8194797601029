import type { MonetateActionType } from '~/monetate/Types/Monetate.type';
import { VendorEventTrackingModel } from '~/tracking/vendor-events/common/Models/VendorEvent.tracking.model';
import { MonetateLoadJSON } from '~/tracking/vendor-events/monetate/Interfaces/IMonetateEvent.tracking';

export class MonetateLoadEventTrackingModel {
	actions

	constructor(actions: MonetateActionType[]) {
		this.actions = actions;
	}

	get keys() {
		const actions = this.actions.map((action: MonetateActionType) => {
			return `${action.json?.component}:${action.json?.region}`;
		}).join('|');
		return actions;
	}

	get trackingJson(): MonetateLoadJSON {
		const vendorEventTrackingModel = new VendorEventTrackingModel();
		const loadJSON = {
			event: 'monetateload',
			content: vendorEventTrackingModel.pageID,
			monetateKeys: this.keys,
		};

		return loadJSON;
	}
}
