import { StorageGiveawayModalDefault } from '~/storage-giveaway-modal/Components/StorageGiveawayModalDefault';

const defaultStorageGiveawayKey = 'DEFAULT';

const storageGiveawayModalComponents = {
	DEFAULT: StorageGiveawayModalDefault,
};

const storageGiveawayModalTimeout = 1000;

export {
	defaultStorageGiveawayKey,
	storageGiveawayModalComponents,
	storageGiveawayModalTimeout,
};
